import React, { useEffect, useMemo, useRef } from "react";

import {
  PlayFrame,
  PlayFrameState,
} from "field-of-things/src/components/PlayFrame";
import { PlayLabel } from "../PlayLabel";
import { PlayContext } from "../PlayContext";
import { isiPad } from "../../util";
import styles from "./ScoutFrame.module.css";
import { Timebase } from "field-of-things/src/models/timebase";
import { PlayFrameOverlay } from "../PlayFrameOverlay";
import { RootStore } from "field-of-things/src/stores";
import { StatsApiNS } from "field-of-things/src/types";
import { toKeyPositionIds } from "field-of-things/src/util/play-util";

interface ScoutFrameProps {
  rootStore: RootStore;
  venueId: number;
  playTracking: StatsApiNS.SkeletalData;
  trackingTimeOffset?: number;
  playData: StatsApiNS.PlayData;
  gumboPlay: any;
  onPlayClicked: () => void;
  initialPlayFrameState?: PlayFrameState;
  onPlayFrameStateChange?: (state: PlayFrameState) => void;
}

export const ScoutFrame: React.FC<ScoutFrameProps> = (props) => {
  const {
    rootStore,
    venueId,
    playTracking,
    trackingTimeOffset = 0,
    playData,
    gumboPlay,
    onPlayClicked,
    initialPlayFrameState,
    onPlayFrameStateChange,
  } = props;

  const timebaseRef = useRef(new Timebase());
  const timebase = timebaseRef.current;

  const playLabel = useMemo(
    () =>
      (gumboPlay && playData && (
        <PlayLabel playData={playData} gumboPlay={gumboPlay} />
      )) ||
      "---",
    [gumboPlay, playData]
  );
  const playContext = useMemo(
    () => playData && <PlayContext playData={playData} />,
    [playData]
  );
  const onIPad = isiPad();
  const scoutFrameStyle = onIPad
    ? styles.scoutFrameIPad
    : styles.scoutFrameGrid;
  const platform = onIPad ? "mobile" : undefined;

  const { useFigureAppearanceStore } = rootStore;
  const setPlayerNamePlatesEnabledPerPlayer = useFigureAppearanceStore(
    ($: any) => $.setPlayerNamePlatesEnabledPerPlayer
  );

  useEffect(() => {
    const involedIds = toKeyPositionIds({ playData: playData }).map((posId) =>
      posId.toString()
    );
    setPlayerNamePlatesEnabledPerPlayer(involedIds);
  }, [playData, setPlayerNamePlatesEnabledPerPlayer]);

  return (
    <div id="scout-frame" className={scoutFrameStyle}>
      <div className={styles.summary} style={{ padding: "0 10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={onPlayClicked}
          >
            {playLabel}
          </div>
          {playContext}
        </div>
      </div>

      <div className={styles.playFrameContainer}>
        <PlayFrame
          timebase={timebase}
          rootStore={rootStore}
          playTracking={playTracking}
          playData={playData}
          venueId={venueId}
          trackingTimeOffset={trackingTimeOffset}
          layout={"hud"}
          fullscreenLayout={"hud"}
          platform={platform}
          initialState={initialPlayFrameState}
          onStateChange={onPlayFrameStateChange}
          Overlay={PlayFrameOverlay}
        />
      </div>
    </div>
  );
};
