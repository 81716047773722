import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button, Tabs } from "@mlb/react-patterns/components";
import styled from "styled-components/macro";
import { AllViews } from "./AllViews";
import { PlayerLocks } from "./PlayerLocks";
import { StatsApiNS } from "field-of-things/src/types";

interface Props {
  playData: StatsApiNS.PlayData;
  setDisplayMoreViews(): void;
  getPlayerById(playerId: number): StatsApiNS.Player | undefined;
  handleViewClick(name: string, isPositionLock: boolean): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      backgroundColor: "#000",
      overflowY: "auto",
      "& button": {
        width: "50%",
      },
      "& [data-selected=true]": {
        color: "#fff",
        borderColor: "orange",
      },
    },
    close: {
      position: "absolute",
      top: "0.3rem",
      right: "1rem",
    },
  })
);

const CloseButton = styled(Button)`
  background-color: #000;
`;

export const ViewOptions: React.FC<Props> = ({
  setDisplayMoreViews,
  handleViewClick,
  getPlayerById,
  playData,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Tabs
          list={[
            {
              children: (
                <AllViews
                  handleViewClick={handleViewClick}
                  setDisplayMoreViews={setDisplayMoreViews}
                />
              ),
              name: "All Views",
            },
            {
              children: (
                <PlayerLocks
                  playData={playData}
                  handleViewClick={handleViewClick}
                  getPlayerById={getPlayerById}
                  setDisplayMoreViews={setDisplayMoreViews}
                />
              ),
              name: "Player Lock",
            },
          ]}
          initialActiveTab={"All Views"}
        />
      </div>
      <CloseButton
        className={classes.close}
        handlers={{
          onClick: () => {
            setDisplayMoreViews();
          },
        }}
        iconName={"close"}
        iconColor={"#fff"}
      />
    </>
  );
};
