import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { targetOptions } from "../PlayFrameOverlay";

interface Props {
  setDisplayMoreViews(): void;
  handleViewClick(name: string, isPositionLock: boolean): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr));",
    },
    view: {
      display: "flex",
      flexFlow: "column-reverse",
      position: "relative",
      margin: "0.4rem",
      width: "24rem",
      height: "12rem",
      cursor: "pointer",
      backgroundColor: "#fff",
      borderRadius: ".6rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    description: {
      backgroundColor: "#000",
      padding: "1rem",
      opacity: "0.8",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    fieldImg: {
      position: "absolute",
    },
  })
);

export const AllViews: React.FC<Props> = ({
  setDisplayMoreViews,
  handleViewClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {targetOptions
        .filter((target) => target.inAllViews)
        .map((target) => (
          <div
            key={target.name}
            onClick={() => {
              handleViewClick(target.name, false);
              setDisplayMoreViews();
            }}
            className={classes.view}
            style={{
              backgroundImage: `url(https://storage.googleapis.com/mlb-bdata-npd-us-east4-scout-data/dev-data/build/images/${target.label.toLowerCase()}.jpg)`,
            }}
          >
            <div className={classes.description}>{target.name}</div>
          </div>
        ))}
    </div>
  );
};
