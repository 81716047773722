import { useMemo } from "react";
import { ScoutFrame } from "./components/ScoutFrame";
import {
  createPlayViewRootStore,
  StoreDefaults,
} from "field-of-things/src/stores";
import { Header } from "./components/Header";
import { getGumboPlay } from "field-of-things/src/util/gumbo-util";
import { prepTracking } from "field-of-things/src/util/tracking-data-util";
import { useOktaAuth } from "@okta/okta-react";
import { ThemeProvider } from "styled-components";

import "./index.css";
import "./App.css";

// Hardcoded play data
import playData from "./data/play_6b3eaa66-5946-4861-af6d-cbed85799151.json";
import trackingData from "./data/tracking_6b3eaa66-5946-4861-af6d-cbed85799151.json";
import gumboData from "./data/gumbo_6b3eaa66-5946-4861-af6d-cbed85799151.json";
import boxscoreData from "./data/boxscore_6b3eaa66-5946-4861-af6d-cbed85799151.json";
import { StatsApiStore } from "field-of-things/src/stores/statsApiStore";

const storeDefaults: StoreDefaults = {
  timeline: { tagsEnabled: false, transparent: true },
  figureAppearance: { playerNamePlatesEnabled: true },
};

export default function App() {
  const { authState } = useOktaAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const authenticatedOverride = queryParams.get("auth") === "true";
  const isMlbDomain = document.location.hostname.endsWith("mlb.com");
  const isUserAuthenticated = isMlbDomain
    ? authState.isAuthenticated
    : authenticatedOverride;
  const signIn =
    isMlbDomain && !isUserAuthenticated ? (
      <button
        onClick={() =>
          (window.location.href = `/login?redirectUri=/fieldvision-beta`)
        }
      >
        Sign in
      </button>
    ) : null;

  // This hack lets the Header check if our user is logged in and sets the proper state on the "sign in"/"account" item
  //@ts-ignore
  window.mlbOkta = {
    isLoggedIn: () => {
      return new Promise((resolve, rejects) =>
        isUserAuthenticated
          ? resolve(isUserAuthenticated)
          : rejects(isUserAuthenticated)
      );
    },
  };

  const playId = "6b3eaa66-5946-4861-af6d-cbed85799151";
  const rootStore = useMemo(() => createPlayViewRootStore(storeDefaults), []);
  const gumboPlay = getGumboPlay({ gumbo: gumboData, playId });

  const useStatsApiStore = rootStore?.useStatsApiStore;
  const setBoxscoreData = useStatsApiStore(
    ($: StatsApiStore) => $.setBoxscoreData
  );
  setBoxscoreData(boxscoreData);

  const [preppedPlayTracking, preppedPlayData] = useMemo(
    () =>
      prepTracking({
        playData,
        playTracking: trackingData,
        jointCleanup: false,
        upsample: false,
        truncatedHitExtensionEnabled: false,
      }),
    []
  );

  const appView = isUserAuthenticated ? (
    <ScoutFrame
      rootStore={rootStore}
      venueId={31}
      playTracking={preppedPlayTracking}
      playData={preppedPlayData}
      gumboPlay={gumboPlay}
      onPlayClicked={() => console.log("playpicker")}
    />
  ) : (
    <div>Not authenticated</div>
  );

  return (
    <ThemeProvider theme={{ dark: true }}>
      <div>
        <Header />
        <div className="App">
          {appView}
          {signIn}
        </div>
      </div>
    </ThemeProvider>
  );
}
