import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Headshot } from "@mlb/react-patterns/components";
import { StatsApiNS } from "field-of-things/src/types";
import {
  allPositions,
  positionIdMap,
} from "field-of-things/src/models/positions";
import _isNil from "lodash/isNil";

interface Props {
  setDisplayMoreViews(): void;
  handleViewClick(name: string, isPositionLock: boolean): void;
  getPlayerById(playerId: number): StatsApiNS.Player | undefined;
  playData: StatsApiNS.PlayData;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(24rem, 1fr));",
    },
    view: {
      display: "flex",
      position: "relative",
      margin: "0.4rem",
      height: "4.2rem",
      cursor: "pointer",
      backgroundColor: "#fff",
      borderRadius: ".6rem",
    },
    description: {
      display: "flex",
      flexFlow: "column",
      backgroundColor: "#000",
      width: "95%",
      alignItems: "flex-start",
      justifyContent: "center",
      opacity: "0.8",
      padding: "0 2rem",
    },
    headshotContainer: {
      minWidth: "4.2rem",
      minHeight: "4.2rem",
      verticalAlign: "middle",
      "& img": {
        borderRadius: "0.6rem",
      },
    },
    position: {
      fontWeight: "bold",
    },
  })
);

export const PlayerLocks: React.FC<Props> = ({
  setDisplayMoreViews,
  getPlayerById,
  handleViewClick,
  playData,
}) => {
  const classes = useStyles();
  const availablePlayers = allPositions
    .filter(
      (position) => position.type === "defense" || position.type === "runner"
    )
    .filter((position) => !_isNil(playData.positionMap[parseInt(position.id)]));
  return (
    <div className={classes.container}>
      {availablePlayers.map((position) => {
        const playerId = playData.positionMap[parseInt(position.id)].id;
        const playerInfo = getPlayerById(playerId);
        return (
          <div
            key={position.id}
            onClick={() => {
              handleViewClick(position.id, true);
              setDisplayMoreViews();
            }}
            className={classes.view}
          >
            <div className={classes.headshotContainer}>
              <Headshot
                width={"100%"}
                height={"100%"}
                zoomScale={1.5}
                rounded={false}
                playerId={playerId}
              />
            </div>
            <div className={classes.description}>
              <div>{playerInfo?.person.initLastName}</div>
              <div className={classes.position}>
                {positionIdMap[position.id].displayShort}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
