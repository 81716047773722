import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ChangeViewButton } from "../ChangeViewButton";
import { ViewOptions } from "../ViewOptions";
import { RootStore } from "field-of-things/src/stores";
import { StatsApiNS } from "field-of-things/src/types";

export interface CameraAngleOption {
  name: string;
  label: string;
  inMenu: boolean;
  inAllViews: boolean;
  isPositionLock: boolean;
  value: number | number[];
}

export const targetOptions: CameraAngleOption[] = [
  {
    name: "Home Plate",
    label: "HP",
    inMenu: true,
    inAllViews: true,
    isPositionLock: false,
    value: [0, 5, 0],
  },
  {
    name: "Mound",
    label: "M",
    inMenu: true,
    inAllViews: true,
    isPositionLock: false,
    value: [0, 5, -60.5],
  },
  {
    name: "1st Base",
    label: "1B",
    inMenu: true,
    inAllViews: true,
    isPositionLock: false,
    value: [63.64, 5, -63.64],
  },
  {
    name: "2nd Base",
    label: "2B",
    inMenu: true,
    inAllViews: true,
    isPositionLock: false,
    value: [0, 5, -127.28],
  },
  {
    name: "3rd Base",
    label: "3B",
    inMenu: true,
    inAllViews: true,
    isPositionLock: false,
    value: [-63.64, 5, -63.64],
  },
  {
    name: "Right Field",
    label: "RF",
    inMenu: false,
    inAllViews: true,
    isPositionLock: false,
    value: [83, 5, -184],
  },
  {
    name: "Center Field",
    label: "CF",
    inMenu: false,
    inAllViews: true,
    isPositionLock: false,
    value: [0, 5, -225],
  },
  {
    name: "Left Field",
    label: "LF",
    inMenu: false,
    inAllViews: true,
    isPositionLock: false,
    value: [-83, 5, -184],
  },
  {
    name: "Batter",
    label: "B",
    inMenu: true,
    inAllViews: false,
    isPositionLock: true,
    value: 10,
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      zIndex: 110,
      "& > *": {
        pointerEvents: "all",
      },
    },
    topLeft: {
      position: "absolute",
      top: "2rem",
      left: "2rem",
      cursor: "pointer",
    },
    bottomRight: {
      position: "absolute",
      bottom: "10rem",
      right: "2rem",
    },
  })
);

export interface OverlayProps {
  rootStore: RootStore;
  playData: StatsApiNS.PlayData;
}

export const PlayFrameOverlay: React.FC<OverlayProps> = ({
  rootStore,
  playData,
}) => {
  const classes = useStyles();
  const [displayMoreViews, setDisplayMoreViews] = useState(false);
  const [selectedCamName, setSelectedCamName] = useState("HP");

  const {
    setCameraTypeOrbitControls,
    setOrbitControlsParams,
    orbitControlsParams,
    setCameraTypeFigureSpinCam,
    gameCamParams,
    setGameCamParams,
  } = rootStore.useCameraStore();

  const { getPlayerById } = rootStore.useStatsApiStore();

  const handleViewClick = (event: string, isPositionLock: boolean) => {
    const value = isPositionLock
      ? event
      : targetOptions.find((opt) => opt.name === event)?.value;
    const positionId: number = isPositionLock
      ? event === "Batter"
        ? 10
        : parseInt(event)
      : 0;
    const target = Array.isArray(value) ? value : [];
    setSelectedCamName(event);
    if (isPositionLock) {
      setCameraTypeFigureSpinCam();
      setGameCamParams({ ...gameCamParams, positionId });
    } else {
      setCameraTypeOrbitControls();
      setOrbitControlsParams({ ...orbitControlsParams, target, positionId });
    }
  };

  return (
    <div className={classes.root}>
      {displayMoreViews ? (
        <ViewOptions
          playData={playData}
          handleViewClick={handleViewClick}
          getPlayerById={getPlayerById}
          setDisplayMoreViews={() => setDisplayMoreViews(false)}
        />
      ) : null}
      <div className={classes.bottomRight}>
        {!displayMoreViews ? (
          <ChangeViewButton
            handleViewClick={handleViewClick}
            selectedCamName={selectedCamName}
            setDisplayMoreViews={() => setDisplayMoreViews(true)}
          />
        ) : null}
      </div>
    </div>
  );
};
