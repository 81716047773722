import React from "react";
import { toBatterLabel, toPitcherLabel, toHalfInningLabel } from "../../util";

interface IPlaySummaryProps {
  playData: any;
  gumboPlay: any;
}

export const PlayLabel = ({ playData, gumboPlay }: IPlaySummaryProps) => {
  let { matchup } = gumboPlay;
  let halfInningLabel = toHalfInningLabel(gumboPlay);
  let pitcherLabel = toPitcherLabel(matchup);
  let batterLabel = toBatterLabel(matchup);
  let description =
    playData?.metaData?.stat?.play?.details?.call?.description || "";
  let text = `${halfInningLabel} ${pitcherLabel} ${batterLabel} – ${description}`;

  let style = {
    cursor: "pointer",
  };

  return <div style={style}>{text}</div>;
};
