//@ts-ignore
import { Header as MlbHeader } from "mlb-react-header";

import { useAsync } from "react-use";

const getDefaultMenuItems = () => {
  const menuItems = [
    "news",
    "video",
    "scores",
    "stats",
    "standings",
    "schedule",
  ].reduce((acc: any, item) => {
    acc.push({
      itemId: item,
      text: item,
      properties: {
        link: `/${item}`,
        customProperties: "",
        icon: item,
      },
    });
    return acc;
  }, []);
  return { menuItems };
};

async function fetchHeaderFooterData() {
  const urls = [
    "https://feen.cms.mlbinfra.com/cms/api/public/menuitems/_navs/header/mlb/global-nav",
    "https://feen.cms.mlbinfra.com/cms/api/public/menuitems/_navs/footer/mlb/global-footer",
    "https://feen.cms.mlbinfra.com/cms/api/public/menuitems/_navs/_shared/shared-nav",
    "https://statsapi.mlb.com/api/v1/teams?sportIds=1&hydrate=venue(timezone,location)",
  ];
  // map every url to the promise of the fetch
  const requests = urls.map((url) => fetch(url));
  const [header, footer, sharedNav, teamsData] = await Promise.all(
    requests
  ).then((responses) => Promise.all(responses.map((r) => r.json())));
  const clubId = "mlb";
  return {
    clubId: `${clubId}`,
    footerData: footer,
    headerData: header,
    locale: "en",
    sharedNav: sharedNav,
    styleProps: {},
    teamsData: teamsData.teams,
  };
}

export const Header = () => {
  const headerProps = useAsync(async () =>
    fetchHeaderFooterData()
      .then((data) => {
        // Fall back to default header items should there be nothing returend from Forge
        if (
          data.headerData.data &&
          data.headerData.data.menuItems &&
          data.headerData.data.menuItems.length > 0
        ) {
          data.headerData = getDefaultMenuItems();
        }
        return data;
      })
      .catch((e) => {
        var data = { headerData: {} };
        data.headerData = getDefaultMenuItems();
        console.error(e);
        return data;
      })
  );
  return <MlbHeader {...headerProps.value} />;
};
