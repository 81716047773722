import React, { useRef, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from '@mlb/react-patterns/components/Button/button';
import { ButtonShapes } from "@mlb/react-patterns/components/Button/button.types";
import styled from "styled-components/macro";
import cx from "classnames";
import { CameraAngleOption, targetOptions } from "../PlayFrameOverlay";

interface Props {
  selectedCamName: string;
  setDisplayMoreViews(): void;
  handleViewClick(name: string, isPositionLock: boolean): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonContainer: {
      display: "flex",
      flexDirection: "column"
    }
  })
);

const CameraAngleButton = styled(Button)`
  background-color: #ddd;
  color: #000;
  margin: 0.2rem;
  opacity: 0.6;
  height: 45px;
  width: 45px;
  border-radius: 25px;
  &:hover {
    background-color: #fff;
  }
  &.cam-select {
    opacity: 1;
  }
  &.current {
    background-color: #ffa801;
    opacity: 1;
  }
`;

export const ChangeViewButton: React.FC<Props> = ({
  selectedCamName,
  setDisplayMoreViews,
  handleViewClick
}) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [targetsVisible, setTargetsVisible] = useState(false);

  const handleMoreViewsClick = () => {
    setDisplayMoreViews();
  }

  return (
    <div className={classes.buttonContainer} ref={ref}>
      {targetsVisible && targetOptions.filter(v => v.inMenu).map((v: CameraAngleOption) => (
          <CameraAngleButton shape={ButtonShapes.PILL_SOLID} className={cx({
            "current": selectedCamName === v.name
          })}
            key={v.name}
            handlers={{
              onClick: () => {
                handleViewClick(v.name, v.isPositionLock);
              }
            }}
          >
            {v.label}
          </CameraAngleButton>
        ))}
      {targetsVisible && <CameraAngleButton shape={ButtonShapes.PILL_SOLID}
          handlers={{
            onClick: () => {
              handleMoreViewsClick();
            }
          }}
        >
          ...
        </CameraAngleButton> }
      <CameraAngleButton iconName={targetsVisible ? "close" : "video"} iconColor={"#000"} shape={ButtonShapes.PILL_SOLID} iconSize={targetsVisible ? 15 : 25} className={cx("cam-select")}
          handlers={{
            onClick: () => setTargetsVisible(!targetsVisible)
          }}
        />
    </div>
  );
};
